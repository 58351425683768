// Load Styles
import '../scss/main.scss';

// Load Bootstrap init
import {initBootstrap} from "./bootstrap.js";

import '@fortawesome/fontawesome-free/js/all.js';

 import {WOW} from 'wowjs';

import $ from "jquery";

// Loading bootstrap with optional features
initBootstrap({
  tooltip: true,
  popover: true,
  toasts: true,
});



var wow = new WOW(
  {
    //    boxClass:     'wow',      // animated element css class (default is wow)
    //offset:       100,
    live: true,
    callback:     function(box) {
      // the callback is fired every time an animation is started
      // the argument that is passed in is the DOM node being animated
    },
    scrollContainer: null // optional scroll container selector, otherwise use window
  }
);
wow.init();

// Your app code
console.log(`Hello ${process.env.HELLO}`);


    // Sticky Navbar
    $(window).scroll(function () {
        if ($(this).scrollTop() > 35) {
            $('.navbar._home').addClass('sticky-top shadow-sm');
        } else {
            $('.navbar._home').removeClass('sticky-top shadow-sm');
        }
    });

    // active menu index
    // 
    
    $('.header._home').find('.nav-link').removeClass('active');

    $('.header._home').find('.nav-link').each(function(index, e){
      //do stuff
      if ($('.header._home .menu .navbar-nav').attr('data-index') == index) {
        $(e).addClass('active');
      }
    });








